import * as React from 'react';
import { gql, useQuery } from '@apollo/client';

import useAuth from './authHook';

const GET_USER = gql`
  query GetUser {
    user {
      accountBalance
      email
      fullName
      firstName
      lastName
      phoneNumber
    }
  }
`;

export type UserType = {
  accountBalance: number;
  email: string;
  fullName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

type UserDataType = {
  user: UserType;
};

export default function useUser(refresh?: boolean) {
  const [user, setUser] = React.useState<UserType | null>(null);

  const auth = useAuth();

  const { data, refetch } = useQuery<UserDataType>(GET_USER, { skip: auth.isAnonymous });

  const refreshUser = async () => {
    await refetch();
    if (data) {
      setUser(data.user);
      return data.user;
    }

    return null;
  };

  React.useEffect(() => {
    if (refresh) refetch().then();

    if (data) {
      setUser(data.user);
    }
  }, [data, refresh, refetch]);

  return { ...auth, user, refreshUser };
}
