import * as React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';

(window as any).APP_TITLE = document.title;

const container = document.createElement('div');
document.body.appendChild(container);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  container
);
