import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {
  ApolloClient,
  ApolloClientOptions,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';

import useAuth from '../hooks/authHook';

import theme from '../theme';

type Props = {
  children: React.ReactNode;
};

export default function ContextProvider({ children }: Props) {
  const { token: authToken } = useAuth();
  const clientOptions: ApolloClientOptions<NormalizedCacheObject> = {
    uri: process.env.REACT_APP_API_URL,
    cache: new InMemoryCache(),
  };
  if (authToken) {
    clientOptions.headers = {
      Authorization: `JWT ${authToken}`,
    };
  }
  const client = new ApolloClient(clientOptions);

  const mui = createMuiTheme(theme.mui);

  return (
    <MuiThemeProvider theme={mui}>
      <ThemeProvider theme={{ ...theme, mui }}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <>{children}</>
          </BrowserRouter>
        </ApolloProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}
