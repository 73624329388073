import * as React from 'react';
import AWS from 'aws-sdk';
import { Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import ContextProvider from '../../contexts/ContextProvider';

import useAuth from '../../hooks/authHook';

import { GlobalStyles } from './styled';

import Account from '../../scenes/Account';

const AuTradelines = React.lazy(() => import('../../scenes/AuTradelines'));
const Login = React.lazy(() => import('../../scenes/Login'));
const SignUp = React.lazy(() => import('../../scenes/SignUp'));
const Order = React.lazy(() => import('../../scenes/Order'));
const ReferFriends = React.lazy(() => import('../../scenes/ReferFriends'));
const Validation = React.lazy(() => import('../../scenes/Validation'));
const Settings = React.lazy(() => import('../../scenes/Settings'));

AWS.config.region = process.env.REACT_APP_AWS_REGION;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_AWS_POOL_ID || '',
});

export default function App() {
  const { isAuthenticated } = useAuth();

  return (
    <ContextProvider>
      <CssBaseline />
      <GlobalStyles />
      <React.Suspense fallback={null}>
        <Switch>
          <Route component={AuTradelines} path="/au-tradelines" />
          <Route component={Login} path="/login/sent/:sentEmail" />
          <Route component={Login} path="/login/:token" />
          <Route component={SignUp} path="/signup" />
          <Route component={ReferFriends} path="/refer-friends" />
          <Route component={Validation} path="/validation" />
          <Route component={Account} path="/account" />
          <Route component={Settings} path="/settings" />
          <Route component={isAuthenticated ? Order : Login} path="/order/:number" />
          <Route component={Account} path="/" exact />
        </Switch>
      </React.Suspense>
    </ContextProvider>
  );
}
