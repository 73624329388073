import * as React from 'react';

export default function useAuth() {
  const STORAGE_KEY = process.env.REACT_APP_JWT_KEY || 'jwt';
  const [token, setToken] = React.useState(localStorage.getItem(STORAGE_KEY) || '');

  const updateToken = (newToken: string) => {
    setToken(newToken);
    if (newToken) {
      localStorage.setItem(STORAGE_KEY, newToken);
    } else {
      localStorage.removeItem(STORAGE_KEY);
    }
  };

  const resetToken = () => updateToken('');

  return {
    token,
    resetToken,
    isAnonymous: !token,
    isAuthenticated: !!token,
    setToken: updateToken,
  };
}
