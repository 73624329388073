import { createGlobalStyle } from 'styled-components';

import { ThemeType } from '../../theme';

export const GlobalStyles = createGlobalStyle<{ theme: ThemeType }>`
  html {
    height: 100%;
  }

  a, button {
    color: inherit;
    cursor: pointer;
    border: 0;
    text-decoration: none;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  input, button, textarea, select {
    font: inherit;
    outline: 0;
  }

  textarea {
    resize: none;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

  input[type="search"] {
    -webkit-appearance: none;
  }

  svg {
    vertical-align: middle;
  }
`;
