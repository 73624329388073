import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import useUser from '../../hooks/userHook';

const Login = React.lazy(() => import('../Login'));
const YourAccount = React.lazy(() => import('./YourAccount'));
const DepositInstructions = React.lazy(() => import('./DepositInstructions'));
const UploadReceipt = React.lazy(() => import('./UploadReceipt'));
const WithdrawFunds = React.lazy(() => import('./WithdrawFunds'));

export default function Account() {
  const { path } = useRouteMatch();
  const { user } = useUser();

  return (
    <React.Suspense fallback={null}>
      {user ? (
        <Switch>
          <Route component={WithdrawFunds} path={`${path}/withdraw-funds`} />
          <Route component={DepositInstructions} path={`${path}/deposit-instructions`} />
          <Route component={UploadReceipt} path={`${path}/upload-receipt`} />
          <Route component={YourAccount} path={path} />
        </Switch>
      ) : (
        <Login />
      )}
    </React.Suspense>
  );
}
