import { Theme } from '@material-ui/core/styles';

const theme = {
  colors: {
    black: '#333',
    black10: 'rgba(0, 0, 0, 0.1)',
    brownGrey: '#828282',
    coral: 'rgba(235, 87, 87, 1)',
    coralDarken: 'rgba(235, 87, 87, 0.84)',
    darkBlueGreen: 'rgba(0, 80, 140, 1)',
    darkBlueGreen20: 'rgba(0, 80, 140, 0.16)',
    darkBlueGreenDarken: 'rgba(25, 80, 140, 0.84)',
    greyishBrown: '#4f4f4f',
    veryLightPink: '#bdbdbd',
    veryLightPinkTwo: '#f2f2f2',
    wheat: 'rgba(255, 200, 120, 1)',
    wheat20: 'rgba(255, 200, 120, 0.16)',
    wheatDarken: 'rgba(255, 200, 120, 0.84)',
    white: '#fff',
  },
  fonts: {
    notoSans: "'Noto Sans', sans-serif",
    notoSerif: "'Noto Serif', serif",
  },
  mui: {
    palette: {
      background: {
        default: '#fff',
      },
    },
    typography: {
      fontFamily: "'Noto Sans', sans-serif",
    },
  } as Theme,
};

export type ThemeType = typeof theme;

export default theme;
